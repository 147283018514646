import React from 'react';
import { BooleanInput, Edit, SimpleForm, TextInput, Toolbar, SaveButton, NumberInput } from 'react-admin';
import CustomDateTimeInput from 'src/components/DatetimeInput';

// 削除ボタンを非表示にするためにカスタムツールバーを定義（下部の更新ボタン）
const CustomToolbar: React.FC = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="更新" />
    </Toolbar>
  );
};

export const UserEdit: React.FC = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="id" disabled />
        <TextInput source="name" disabled />

        <TextInput source="email" />

        <TextInput source="firebaseId" disabled />
        <CustomDateTimeInput source="validFrom" />
        <CustomDateTimeInput source="validUntil" />
        <TextInput source="jobTitleName" disabled />
        <BooleanInput source="jobTitleBillable" disabled />
        <NumberInput source="privilege" />
      </SimpleForm>
    </Edit>
  );
};
