import * as React from 'react';
import { render } from 'react-dom';
import { Route } from 'react-router-dom';
import { Admin, Resource, CustomRoutes, defaultTheme } from 'react-admin';
import dataProvider from 'src/dataProvider';
import authProvider from 'src/authProvider';
import { OrganizationList, OrganizationCreate, OrganizationEdit, OrganizationIcon } from 'src/resources/organization';
import { AdminUserList, AdminUserCreate, AdminUserIcon } from 'src/resources/adminUser';
import { PermissionList, PermissionIcon } from 'src/resources/permission';
import { BookList, BookIcon, BookCreate, BookEdit } from 'src/resources/book';
import { ReviewedBookList, ReviewedBookEdit, ReviewedBookIcon } from 'src/resources/reviewedBook';
import { DocList, DocShow, DocEdit, DocIcon } from 'src/resources/doc';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import translation from 'src/translation';
import ExternalLink from 'src/components/ExternalLink';
import { TrialUserRequestList, TrialUserRequestIcon } from 'src/resources/trialUserRequest';
import { RequestIcon, RequestList } from './resources/request';
import { RequestShow } from './resources/request/show';
import { TrialUserRequestShow } from './resources/trialUserRequest/show';
import { TrialUserRequestCreate } from './resources/trialUserRequest/create';
import { TaskList } from './resources/task';
import { UserIcon, UserList } from './resources/user';
import { OrganizationShow } from './resources/organization/show';
import { UserCreate } from './resources/user/create';
import { BookShow } from './resources/book/show';
import { ReviewedBookShow } from './resources/reviewedBook/show';
import { PrinterCreate, PrinterEdit, PrinterIcon, PrinterList } from './resources/printer';
import { PrinterShow } from './resources/printer/show';
import { PdfCreate, PdfList } from './resources/pdf';
import { LabsWandhPlanList } from './resources/labsWandhPlan';
import { JobTitleIcon, JobTitleList } from './resources/jobTitle';
import { FormatCategoryIcon, FormatCategoryList } from './resources/formatCategory';
import { FormatSubcategoryIcon, FormatSubcategoryList } from './resources/formatSubCategory';
import { FormatUseIcon, FormatUseList } from './resources/formatUse';
import blue from '@mui/material/colors/blue';
import orange from '@mui/material/colors/orange';
import lime from '@mui/material/colors/lime';
import { UserShow } from './resources/user/show';
import { UserEdit } from './resources/user/update';

const i18nProvider = polyglotI18nProvider(() => translation, 'ja');

function getPrimaryColor() {
  if (window.location.hostname.startsWith('admin.dev')) {
    return orange[500];
  } else if (window.location.hostname.startsWith('admin.stage')) {
    return lime[500];
  } else {
    return blue[500];
  }
}

render(
  <Admin
    theme={{
      ...defaultTheme,
      palette: {
        secondary: {
          main: getPrimaryColor(),
        },
      },
    }}
    requireAuth
    dataProvider={dataProvider('/admin-api/v1')}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    loginPage={false}
    disableTelemetry
  >
    <Resource
      name="organizations"
      list={OrganizationList}
      show={OrganizationShow}
      create={OrganizationCreate}
      edit={OrganizationEdit}
      icon={OrganizationIcon}
    />
    <Resource name="adminUsers" list={AdminUserList} create={AdminUserCreate} icon={AdminUserIcon} />
    <Resource name="permissions" list={PermissionList} icon={PermissionIcon} />
    <Resource name="users" list={UserList} create={UserCreate} show={UserShow} edit={UserEdit} icon={UserIcon} />
    <Resource
      name="trialUserRequests"
      list={TrialUserRequestList}
      show={TrialUserRequestShow}
      create={TrialUserRequestCreate}
      icon={TrialUserRequestIcon}
    />
    <Resource name="requests" list={RequestList} show={RequestShow} icon={RequestIcon} />
    <Resource name="tasks" list={TaskList} />

    <Resource name="books" list={BookList} show={BookShow} create={BookCreate} edit={BookEdit} icon={BookIcon} />

    <Resource
      name="reviewedBooks"
      list={ReviewedBookList}
      show={ReviewedBookShow}
      edit={ReviewedBookEdit}
      icon={ReviewedBookIcon}
    />
    <Resource name="docs" list={DocList} show={DocShow} edit={DocEdit} icon={DocIcon} />
    <Resource name="pdf" list={PdfList} create={PdfCreate} />
    <Resource name="packs" recordRepresentation="name" />
    <Resource
      name="printers"
      list={PrinterList}
      show={PrinterShow}
      create={PrinterCreate}
      edit={PrinterEdit}
      icon={PrinterIcon}
    />

    <Resource name="formatCategories" list={FormatCategoryList} icon={FormatCategoryIcon} />
    <Resource name="formatSubcategories" list={FormatSubcategoryList} icon={FormatSubcategoryIcon} />
    <Resource name="formatUses" list={FormatUseList} icon={FormatUseIcon} />

    <Resource name="labsWandhPlans" list={LabsWandhPlanList} />

    <Resource name="jobTitles" list={JobTitleList} icon={JobTitleIcon} />

    <CustomRoutes noLayout>
      <Route
        path="/login"
        element={<ExternalLink url={`/auth/logout?return=${encodeURIComponent(window.location.href)}`} />}
      />
    </CustomRoutes>
  </Admin>,
  document.getElementById('root'),
);
