import * as React from 'react';
import {
  Edit,
  FunctionField,
  Labeled,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
} from 'react-admin';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { FormGlobalError } from 'src/components/FormGlobalError';
import DatetimeField from 'src/components/DatetimeField';
import { DocRecord } from './index';
import { purchaseType } from '../book/field';

const ReviewedBookEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const DocEdit: React.FC = () => {
  // packIds を string から number に変換する
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformForSave = (data: any) => {
    if (data.packIds && Array.isArray(data.packIds)) {
      data.packIds = data.packIds.map((id: string | number) => (typeof id === 'string' ? Number(id) : id));
    }
    return data;
  };

  return (
    <Edit mutationMode="pessimistic" transform={transformForSave}>
      <SimpleForm shouldUnregister={true} toolbar={<ReviewedBookEditToolbar />}>
        <FormGlobalError />

        <Labeled>
          <TextField source="id" />
        </Labeled>
        <Labeled>
          <TextField source="type" />
        </Labeled>
        <Labeled>
          <TextField source="title" />
        </Labeled>
        <Labeled>
          <FunctionField
            source="authors"
            render={(record?: { authors: string[] }) => {
              return record?.authors.join('、');
            }}
          />
        </Labeled>
        <Labeled>
          <TextField source="publisher" />
        </Labeled>
        <ReferenceArrayInput source="packIds" reference="packs" />
        <Labeled>
          <FunctionField
            source="planIds"
            render={(record?: DocRecord) => {
              if (record?.planIds?.length) {
                return record.planIds.join('、');
              }
              return '';
            }}
          />
        </Labeled>
        <CustomDateTimeInput
          source="availableFrom"
          helperText="掲載期間を指定しない場合、現時点から閲覧可能を意味します"
        />
        <CustomDateTimeInput
          source="availableUntil"
          helperText="掲載期間を指定しない場合、無期限に閲覧可能を意味します"
        />
        <SelectInput source="purchaseType" choices={purchaseType} optionValue={'id'} />
        <Labeled>
          <DatetimeField source="reservableFrom" />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
