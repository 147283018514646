import React from 'react';
import { BooleanField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import DatetimeField from 'src/components/DatetimeField';

const UserTile = () => {
  const record = useRecordContext();
  return record ? <span>{record.name}</span> : null;
};

export const UserShow: React.FC = () => {
  return (
    <Show title={<UserTile />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="firebaseId" />
        <TextField source="msObjectId" />
        <DatetimeField source="validFrom" />
        <DatetimeField source="validUntil" />
        <TextField source="jobTitleName" />
        <BooleanField source="jobTitleBillable" />
        <DatetimeField source="createdAt" />
        <TextField source="privilege" />
      </SimpleShowLayout>
    </Show>
  );
};
