export const pricingTypes = [
  { id: 'lawFirm', name: 'enum.pricingType.lawFirm' },
  { id: 'companyLegalDepartment', name: 'enum.pricingType.companyLegalDepartment' },
  { id: 'lawFirmSmall', name: 'enum.pricingType.lawFirmSmall' },
  { id: 'lawFirmMedium', name: 'enum.pricingType.lawFirmMedium' },
  { id: 'lawFirmLarge', name: 'enum.pricingType.lawFirmLarge' },
  { id: 'lawFirmBig5', name: 'enum.pricingType.lawFirmBig5' },
  { id: 'companyLegalDepartmentNormal', name: 'enum.pricingType.companyLegalDepartmentNormal' },
  { id: 'lawFirmCustomized', name: 'enum.pricingType.lawFirmCustomized' },
  { id: 'companyLegalDepartmentOtameshi', name: 'enum.pricingType.companyLegalDepartmentOtameshi' },
  { id: 'legalOn', name: 'enum.pricingType.legalOn' },
];

export const contractUserCountTypes = [
  { id: 'fixed', name: 'enum.contractUserCountType.fixed' },
  { id: 'variable', name: 'enum.contractUserCountType.variable' },
];

export const loginTypes = [
  { id: 'password', name: 'enum.loginType.password' },
  { id: 'microsoft', name: 'enum.loginType.microsoft' },
  { id: 'google', name: 'enum.loginType.google' },
  { id: 'emailLink', name: 'enum.loginType.emailLink' },
];

export const organizationTypeIds = [
  { id: '1', name: 'enum.organizationTypeId.1' },
  { id: '2', name: 'enum.organizationTypeId.2' },
  { id: '3', name: 'enum.organizationTypeId.3' },
  { id: '999', name: 'enum.organizationTypeId.999' },
];

export const sellerIds = [
  { id: 1, name: 'enum.sellerId.1' },
  { id: 2, name: 'enum.sellerId.2' },
  { id: 3, name: 'enum.sellerId.3' },
];

export const labsWandhD1OptionTypes = [
  { id: 'allUsers', name: 'enum.labsWandhD1OptionType.allUsers' },
  { id: 'organization', name: 'enum.labsWandhD1OptionType.organization' },
  { id: 'specifiedUsers', name: 'enum.labsWandhD1OptionType.specifiedUsers' },
];

export const labsWandhD1OptionUserTypes = [
  { id: 'paid', name: 'enum.labsWandhD1OptionUserType.paid' },
  { id: 'free', name: 'enum.labsWandhD1OptionUserType.free' },
];
