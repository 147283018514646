import * as React from 'react';
import { Fragment, useState } from 'react';

import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  Button,
  Confirm,
  useDataProvider,
  useListContext,
  useNotify,
  useTranslate,
  useRecordContext,
  BooleanField,
  SelectInput,
  TextInput,
} from 'react-admin';
import {
  Box,
  Modal,
  Button as MuiButton,
  DialogContentText,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import DatetimeField from 'src/components/DatetimeField';
import { useMutation } from 'react-query';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { DatagridBulkActionDialog } from '../../components/DatagridBulkActionDialog';
export const UserIcon = PeopleIcon;

type User = {
  accountId: string | null;
  createdAt: string;
  email: string;
  privilege: number;
  firebaseId: string;
  msObjectId: string | null;
  id: string;
  name: string;
  validFrom: string;
  validUntil: string | null;
};

const filters = [
  <ReferenceInput source="oid" reference="organizations" alwaysOn>
    <AutocompleteInput optionValue="oid" optionText="name" label="resources.organizations.name" sx={{ width: 450 }} />
  </ReferenceInput>,
  <TextInput source="q" label="検索" alwaysOn sx={{ width: 450 }} />,
  <SelectInput
    label="アカウントID"
    source="accountId"
    choices={[
      { id: 'exists', name: '存在する' },
      { id: 'null', name: '存在しない' },
    ]}
    alwaysOn
  />,
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '3px',
  p: 4,
};
const ellipsis: React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '120px',
  display: 'block',
};
const ellipsisContainer: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '150px',
};

type BulkActionDialogProps = {
  label: string;
  icon: React.ReactNode;
  process: 'createOrAssociateAccounts';
};

const TableColumnRow: React.FC = () => {
  return (
    <TableRow>
      <TableCell component="th">OID</TableCell>
      <TableCell component="th">ID</TableCell>
      <TableCell component="th">名前</TableCell>
      <TableCell component="th">Email</TableCell>
      <TableCell component="th">アカウント ID</TableCell>
      <TableCell component="th">Firebase ID</TableCell>
    </TableRow>
  );
};

const buildTableRecordRow = (oid: string) => {
  return (props: { record: User }) => {
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {oid}
        </TableCell>
        <TableCell component="th" scope="row">
          {props.record.id}
        </TableCell>
        <TableCell>{props.record.name}</TableCell>
        <TableCell>{props.record.email}</TableCell>
        <TableCell>{props.record.accountId || '-'}</TableCell>
        <TableCell>{props.record.firebaseId}</TableCell>
      </TableRow>
    );
  };
};

const BulkActionDialog: React.FC<BulkActionDialogProps> = (props) => {
  const dataProvider = useDataProvider();
  const { selectedIds, filterValues } = useListContext();

  const resource = 'users';
  const path = `${resource}/${props.process}`;
  const body = { data: { ids: selectedIds } };

  return (
    <DatagridBulkActionDialog
      resource={resource}
      label={props.label}
      icon={props.icon}
      callApi={async () => {
        const response = await dataProvider.create(path, body);
        return response.data;
      }}
      TableColumnRow={TableColumnRow}
      TableRow={buildTableRecordRow(filterValues.oid)}
    />
  );
};

const BulkActionButtons: React.FC = () => (
  <Fragment>
    <BulkActionDialog label="アカウント作成 / 関連付け" process="createOrAssociateAccounts" icon={<GroupAddIcon />} />
  </Fragment>
);

export const ResetButton: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { email, id } = useRecordContext();

  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const onSuccess = (result: { data: { password: string } }) => {
    setMessage(translate('messages.resetUserPassword', { password: result.data.password }));
    setOpen(true);
  };
  const { mutateAsync, isLoading } = useMutation(() => dataProvider.invoke(`users/${id}/resetPassword`, {}), {
    onSuccess,
  });
  const handleClose = () => {
    setOpen(false);
  };

  const name = translate(`resources.users.resetPassword.title`);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button color="primary" onClick={() => setIsDialogOpen(true)} label="label.reset" />
      <Confirm
        isOpen={isDialogOpen}
        loading={isLoading}
        title={name}
        content={
          <>
            <DialogContentText>
              email: <strong>{email}</strong>
            </DialogContentText>
            <DialogContentText>{translate('messages.confirm_invoke', { name })}</DialogContentText>
          </>
        }
        confirm={'label.invoke'}
        ConfirmIcon={() => (isLoading ? <PendingIcon /> : <CheckCircle />)}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={async () => {
          try {
            await mutateAsync();
            notify('notification.completed', {
              type: 'success',
            });
          } catch (e) {
            console.error(e);
            notify('error.unknown', {
              type: 'error',
            });
          } finally {
            setIsDialogOpen(false);
          }
        }}
      />
      <Modal open={open}>
        <Box sx={style}>
          <Typography>{message}</Typography>
          <Grid container justifyContent="flex-end">
            <MuiButton
              sx={{ mt: 4 }}
              variant="contained"
              disableElevation
              startIcon={<CloseIcon />}
              onClick={handleClose}
            >
              閉じる
            </MuiButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export const UserList: React.FC = () => {
  const notify = useNotify();
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      notify('コピーしました', { type: 'info' });
    });
  };
  return (
    <List filters={filters} perPage={100} sort={{ field: 'createdAt', order: 'DESC' }} empty={false}>
      <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="email" sortable={false} />
        <FunctionField
          source="accountId"
          sortable={false}
          render={(record: User) =>
            record.accountId ? (
              <div style={ellipsisContainer}>
                <Tooltip title={record.accountId} arrow>
                  <span style={ellipsis}>{record.accountId}</span>
                </Tooltip>
                <IconButton size="small" onClick={() => handleCopy(record.accountId || '')}>
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </div>
            ) : (
              '-'
            )
          }
        />
        <FunctionField
          source="firebaseId"
          sortable={false}
          render={(record: User) => (
            <div style={ellipsisContainer}>
              <Tooltip title={record.firebaseId} arrow>
                <span style={ellipsis}>{record.firebaseId}</span>
              </Tooltip>
              <IconButton size="small" onClick={() => handleCopy(record.firebaseId || '')}>
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        />
        <FunctionField
          source="msObjectId"
          sortable={false}
          render={(record: User) => (
            <div style={ellipsisContainer}>
              <Tooltip title={record.msObjectId} arrow>
                <span style={ellipsis}>{record.msObjectId || '-'}</span>
              </Tooltip>
              {record.msObjectId && (
                <IconButton size="small" onClick={() => handleCopy(record.msObjectId || '')}>
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          )}
        />
        <DatetimeField source="validFrom" sortable={false} />
        <DatetimeField source="validUntil" sortable={false} />
        <TextField source="jobTitleName" sortable={false} />
        <BooleanField source="jobTitleBillable" sortable={false} />
        <DatetimeField source="createdAt" sortable={false} />
        <FunctionField
          source="privilege"
          sortable={false}
          render={(record: User) => (record.privilege === 1 ? '権限あり' : '権限なし')}
        />
        <ResetButton />
      </Datagrid>
    </List>
  );
};
