import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ArrayField,
  Datagrid,
  useRecordContext,
  WrapperField,
  NumberField,
  Labeled,
  FunctionField,
} from 'react-admin';
import { Grid, Divider } from '@mui/material';
import ContractUserCountFields from 'src/components/ContractUserCountFields';
import LabsWandhD1OptionFields from 'src/components/LabsWandhD1OptionFields';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from 'src/components/TranslatedField';

const OrganizationTitle = () => {
  const record = useRecordContext();
  // ロード中は空
  if (!record) return null;
  return <span>{record.name}</span>;
};

export const OrganizationShow: React.FC = () => (
  <Show title={<OrganizationTitle />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="id" />
      <TextField source="subdomain" />
      <TextField source="oid" />
      <WrapperField label="トライアル">
        <DatetimeField source="trialFrom" />
        〜
        <DatetimeField source="trialUntil" />
      </WrapperField>
      <WrapperField label="本利用">
        <DatetimeField source="paidFrom" />
        〜
        <DatetimeField source="paidUntil" />
      </WrapperField>
      <TranslatedField source="sellerId" prefix="enum.sellerId" />
      <TranslatedField source="pricingType" prefix="enum.pricingType" />
      <ContractUserCountFields />
      <TranslatedField source="loginType" prefix="enum.loginType" />
      <ArrayField source="permissions">
        <Datagrid bulkActionButtons={false}>
          <ReferenceField source="id" reference="permissions" label="パーミッション">
            <TextField source="name" />
          </ReferenceField>
          <DatetimeField source="validFrom" />
          <DatetimeField source="validUntil" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="packs">
        <Datagrid bulkActionButtons={false}>
          <TextField source="packId" />
          <DatetimeField source="validFrom" />
          <DatetimeField source="validUntil" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="labsWandhPlans">
        <Datagrid bulkActionButtons={false}>
          <TextField source="basePlan.name" />
          <TextField source="addOnPlan.name" />
          <NumberField source="maxAddOnPlanUsers" />
          <DatetimeField source="activeFrom" showTime />
          <DatetimeField source="activeUntil" showTime />
        </Datagrid>
      </ArrayField>

      <FunctionField
        render={(record: object & { pricingType: string }) =>
          // 料金プランが 3,4,5,6 のときのみディスカウント設定ができるため、それ以外の場合は非表示
          ['lawFirmSmall', 'lawFirmMedium', 'lawFirmLarge', 'lawFirmBig5'].includes(record.pricingType) && (
            <>
              <Divider sx={{ mb: 4 }} flexItem />

              <WrapperField source="lawFirmTieredPricingCondition">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Labeled>
                      <NumberField source="lawFirmTieredPricingCondition.pageViewCountThreshold" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={4}>
                    <Labeled>
                      <NumberField source="lawFirmTieredPricingCondition.searchCountThreshold" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={4}>
                    <Labeled>
                      <NumberField source="lawFirmTieredPricingCondition.availableDaysThreshold" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={4}>
                    <Labeled>
                      <NumberField source="lawFirmTieredPricingCondition.minChargeUserCount" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={4}>
                    <Labeled>
                      <NumberField source="lawFirmTieredPricingCondition.maxChargeUserCount" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Labeled>
                      <NumberField source="lawFirmTieredPricingCondition.minChargeUserPercent" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={4}>
                    <Labeled>
                      <NumberField source="lawFirmTieredPricingCondition.maxChargeUserPercent" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </WrapperField>
            </>
          )
        }
      />

      <LabsWandhD1OptionFields />
    </SimpleShowLayout>
  </Show>
);
