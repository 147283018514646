import { ArrayField, Datagrid, FunctionField, Labeled, RaRecord, TextField, WrapperField } from 'react-admin';
import { Stack } from '@mui/material';
import DatetimeField from './DatetimeField';
import TranslatedField from './TranslatedField';

const LabsWandhD1OptionFields = () => {
  const organizationTypeIds = {
    legalDepartment: '1',
    lawFirm: '2',
    professionalFirm: '3',
  };
  const optionTypes = {
    allUsers: 'allUsers',
    organization: 'organization',
    specifiedUsers: 'specifiedUsers',
  } as const;

  type OptionTypeValue = typeof optionTypes[keyof typeof optionTypes];

  const optionUsersLabel = (type: OptionTypeValue | undefined) => {
    switch (type) {
      case optionTypes.organization:
        return '有償/無償判例連携利用可能スタッフ一覧';
      case optionTypes.specifiedUsers:
        return 'ユーザー一覧';
      default:
        return '';
    }
  };

  return (
    <Labeled component="div" sx={{ width: '100%' }}>
      <WrapperField source="labsWandhD1Option">
        <FunctionField
          component="div"
          render={(record: RaRecord | undefined) => {
            if (record?.labsWandhD1Option?.validFrom === null) {
              return '未設定';
            }

            return (
              <Stack gap={1}>
                <Stack direction="row">
                  <DatetimeField source="labsWandhD1Option.validFrom" />
                  〜
                  <DatetimeField source="labsWandhD1Option.validUntil" />
                </Stack>
                <Labeled>
                  <TranslatedField source="labsWandhD1Option.type" prefix="enum.labsWandhD1OptionType" />
                </Labeled>
                {record?.labsWandhD1Option?.type === 'allUsers' ? null : (
                  <>
                    {(record?.organizationTypeId === organizationTypeIds.lawFirm ||
                      record?.organizationTypeId === organizationTypeIds.professionalFirm) && (
                      <Stack>
                        {record?.labsWandhD1Option?.type === optionTypes.specifiedUsers && (
                          <Labeled
                            sx={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              '& > .MuiTypography-root': { mb: 0 },
                            }}
                          >
                            <TextField
                              source="labsWandhD1Option.userCounts.billableJobUser"
                              variant="caption"
                              sx={{ '&::before': { content: "'： '" } }}
                            />
                          </Labeled>
                        )}
                        <Labeled
                          sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            '& > .MuiTypography-root': { mb: 0 },
                          }}
                        >
                          <TextField
                            source="labsWandhD1Option.userCounts.paidStaff"
                            variant="caption"
                            sx={{ '&::before': { content: "'： '" } }}
                          />
                        </Labeled>
                        <Labeled
                          sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            '& > .MuiTypography-root': { mb: 0 },
                          }}
                        >
                          <TextField
                            source="labsWandhD1Option.userCounts.freeStaff"
                            variant="caption"
                            sx={{ '&::before': { content: "'： '" } }}
                          />
                        </Labeled>
                      </Stack>
                    )}
                    <Labeled component="div" label={optionUsersLabel(record?.labsWandhD1Option?.type)}>
                      <>
                        {record?.labsWandhD1Option?.users.length > 0 ? (
                          <ArrayField source="labsWandhD1Option.users">
                            <Datagrid bulkActionButtons={false}>
                              <TextField
                                source="email"
                                label="resources.organizations.fields.labsWandhD1Option.users.email"
                              />
                              <DatetimeField source="validFrom" />
                              <DatetimeField source="validUntil" />
                              <TranslatedField
                                source="type"
                                prefix="enum.labsWandhD1OptionUserType"
                                label="resources.organizations.fields.labsWandhD1Option.users.type"
                              />
                            </Datagrid>
                          </ArrayField>
                        ) : (
                          '未設定'
                        )}
                      </>
                    </Labeled>
                  </>
                )}
              </Stack>
            );
          }}
        />
      </WrapperField>
    </Labeled>
  );
};
export default LabsWandhD1OptionFields;
