import * as React from 'react';
import { useEffect } from 'react';
import type { RaRecord, Validator } from 'react-admin';
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  FunctionField,
  ReferenceInput,
  RemoveItemButton,
  SelectInput,
  SimpleFormIterator,
  NumberInput,
  required,
  useTranslate,
  number,
  minValue,
  maxValue,
  email,
  useRecordContext,
  useDataProvider,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import DatetimeInput from 'src/components/DatetimeInput';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { labsWandhD1OptionTypes, labsWandhD1OptionUserTypes } from './field';
import { isValidDuration } from 'src/utils/duration';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export type PermissionsInputProps = {
  defaultValue?: number[];
};

export const PermissionsInput: React.FC<PermissionsInputProps> = ({ defaultValue }) => {
  const defaultValidFrom = new Date();
  defaultValidFrom.setHours(0, 0, 0, 0);

  const translate = useTranslate();
  const { resetField } = useFormContext();

  useEffect(() => {
    if (defaultValue === undefined) return;

    resetField('permissions', {
      defaultValue: defaultValue.map((id) => ({ id, validFrom: null, validUntil: null })),
    });
  }, [resetField, defaultValue]);

  type ValidatePermission = (
    value: number,
    values: { permissions: { id: number }[] },
    props: { source: string },
  ) => string | undefined;
  const validatePermission: ValidatePermission = (value, values, props) => {
    const { source } = props;
    const index = Number(source.split('.')[1]);

    let error;
    // パーミッション ID が重複しているものだけを抽出
    const duplicates = values.permissions.map(({ id }, i) => ({ id, i })).filter(({ id }) => id === value);
    // 重複が自分自身以外の場合はバリデーションエラー
    if (duplicates.findIndex(({ i }) => i === index) !== 0) {
      error = '権限が重複しています';
    }

    return error;
  };

  type ValidateValidUntil = (scopedFormData: {
    id: number;
    validFrom: Date | string;
    validUntil: Date | string | null;
  }) => Validator;
  const validateValidUntil: ValidateValidUntil = (scopedFormData) => {
    return (validUntil: Date | string | null, _values, _props) => {
      let error;
      const { validFrom } = scopedFormData;
      const _validFrom = validFrom ? new Date(validFrom) : validFrom;
      const _validUntil = validUntil ? new Date(validUntil) : validUntil;
      if (_validUntil && _validFrom && _validUntil <= _validFrom) {
        error = '適用終了日は適用開始日より未来の日付を入力してください';
      }

      return error;
    };
  };

  return (
    <>
      <ArrayInput source="permissions">
        <SimpleFormIterator disableReordering inline>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) =>
              getSource && (
                <>
                  <ReferenceInput
                    reference="permissions"
                    source={getSource('id')}
                    validate={[required()]}
                    sort={{ field: 'id', order: 'ASC' }}
                    perPage={1000}
                  >
                    <SelectInput
                      optionText={(choice) => translate(`enum.permissionName.${choice.name}`, { _: choice.name })}
                      validate={[required(), validatePermission]}
                    />
                  </ReferenceInput>
                  <DatetimeInput
                    source={getSource('validFrom')}
                    defaultValue={defaultValidFrom}
                    validate={[required()]}
                  />
                  <DatetimeInput source={getSource('validUntil')} validate={validateValidUntil(scopedFormData)} />
                </>
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

/**
 * LawFirmTieredPricingCondition の値を入力するための Input 群
 */
export const LawFirmTieredPricingConditionInput: React.FC = () => {
  const translate = useTranslate();
  const fields = ['minChargeUserCount', 'maxChargeUserCount', 'minChargeUserPercent', 'maxChargeUserPercent'];

  const translation = fields.reduce((acc, field) => {
    acc[field] = translate(`resources.lawFirmTieredPricingConditions.fields.${field}`);
    return acc;
  }, {} as Record<string, string>);

  type Value = number | null | undefined;
  type AllValue = {
    lawFirmTieredPricingCondition: {
      pageViewCountThreshold: Value;
      searchCountThreshold: Value;
      availableDaysThreshold: Value;
      minChargeUserCount: Value;
      maxChargeUserCount: Value;
      minChargeUserPercent: Value;
      maxChargeUserPercent: Value;
    };
  };

  const validateInteger = (value: Value) => {
    if (typeof value !== 'number') return;

    if (!Number.isInteger(value)) {
      return `整数を入力してください。`;
    }
  };

  const validateMutualExclusion = (value: Value, otherValue: Value, fieldName: string, otherFieldName: string) => {
    if (value && otherValue) {
      return `${translation[fieldName]}と${translation[otherFieldName]}は同時に指定できません。`;
    }
  };

  const validateRequiredInput = (value: Value, otherValue: Value, fieldName: string, otherFieldName: string) => {
    if (!value && value !== 0 && !otherValue) {
      return `${translation[fieldName]}と${translation[otherFieldName]}のどちらかに値を入力してください。`;
    }
  };

  const validateRange = (value: Value, otherValue: Value, fieldName: string, comparison: 'greater' | 'less') => {
    if (typeof value !== 'number' || typeof otherValue !== 'number') return;

    if (comparison === 'greater' && value <= otherValue) {
      return `${translation[fieldName]}より大きい値を入力してください。`;
    }
    if (comparison === 'less' && value >= otherValue) {
      return `${translation[fieldName]}より小さい値を入力してください。`;
    }
  };

  const validateMinChargeUserCount = (
    value: Value,
    { lawFirmTieredPricingCondition: { minChargeUserPercent, maxChargeUserCount } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      minChargeUserPercent,
      'minChargeUserCount',
      'minChargeUserPercent',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      minChargeUserPercent,
      'minChargeUserCount',
      'minChargeUserPercent',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, maxChargeUserCount, 'maxChargeUserCount', 'less');
  };

  const validateMinChargeUserPercent = (
    value: Value,
    { lawFirmTieredPricingCondition: { minChargeUserCount, maxChargeUserPercent } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      minChargeUserCount,
      'minChargeUserPercent',
      'minChargeUserCount',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      minChargeUserCount,
      'minChargeUserPercent',
      'minChargeUserCount',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, maxChargeUserPercent, 'maxChargeUserPercent', 'less');
  };

  const validateMaxChargeUserCount = (
    value: Value,
    { lawFirmTieredPricingCondition: { maxChargeUserPercent, minChargeUserCount } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      maxChargeUserPercent,
      'maxChargeUserCount',
      'minChargeUserPercent',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      maxChargeUserPercent,
      'maxChargeUserCount',
      'maxChargeUserPercent',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, minChargeUserCount, 'minChargeUserCount', 'greater');
  };

  const validateMaxChargeUserPercent = (
    value: Value,
    { lawFirmTieredPricingCondition: { maxChargeUserCount, minChargeUserPercent } }: AllValue,
  ) => {
    const requiredError = validateRequiredInput(
      value,
      maxChargeUserCount,
      'maxChargeUserPercent',
      'maxChargeUserCount',
    );
    if (requiredError) return requiredError;

    const mutualExclusionError = validateMutualExclusion(
      value,
      maxChargeUserCount,
      'maxChargeUserPercent',
      'maxChargeUserCount',
    );
    if (mutualExclusionError) return mutualExclusionError;

    return validateRange(value, minChargeUserPercent, 'minChargeUserPercent', 'greater');
  };

  // 共通のバリデーション設定
  const commonValidation = [number(), validateInteger, minValue(0)];

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        // 料金プランが 3,4,5,6 のいずれかの場合にのみ表示する
        ['lawFirmSmall', 'lawFirmMedium', 'lawFirmLarge', 'lawFirmBig5'].includes(formData.pricingType) && (
          <>
            <Typography variant="body2">{translate('resources.lawFirmTieredPricingConditions.name')}</Typography>

            <Grid container spacing={1} columns={16}>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.pageViewCountThreshold"
                  validate={commonValidation}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.searchCountThreshold"
                  validate={commonValidation}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.availableDaysThreshold"
                  validate={commonValidation}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={10} />

              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.minChargeUserCount"
                  validate={[...commonValidation, validateMinChargeUserCount]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.maxChargeUserCount"
                  validate={[...commonValidation, validateMaxChargeUserCount]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={12} />

              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.minChargeUserPercent"
                  validate={[...commonValidation, maxValue(100), validateMinChargeUserPercent]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={2}>
                <NumberInput
                  source="lawFirmTieredPricingCondition.maxChargeUserPercent"
                  validate={[...commonValidation, maxValue(100), validateMaxChargeUserPercent]}
                  step={1}
                  {...rest}
                />
              </Grid>
              <Grid item xs={12} />
            </Grid>
          </>
        )
      }
    </FormDataConsumer>
  );
};

/**
 * 判例連携オプションの値を入力するための Input 群
 */
export type LabsWandhD1OptionInputProps = {
  allowUserListSettings?: boolean;
};
export const LabsWandhD1OptionInput: React.FC<LabsWandhD1OptionInputProps> = ({ allowUserListSettings = true }) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const record = useRecordContext();
  const { getValues, setValue } = useFormContext();

  const isMounted = React.useRef(false);

  // オプションを利用するかどうか
  const [useOption, setOption] = React.useState(false);

  useEffect(() => {
    // record.labsWandhD1Optionが取得されるまではスルー
    if (isMounted.current || record?.labsWandhD1Option === undefined) return;

    // オプション設定がされている場合は、useOptionの初期値をtrueにする
    // NOTE: 設定する場合はvalidFromが必須なので、validFromがnullでない場合にtrueにしている
    setOption(record.labsWandhD1Option.validFrom !== null);
    isMounted.current = true;
  }, [useOption, record?.labsWandhD1Option]);

  const [users, setUsers] = React.useState<RaRecord[]>([]);

  React.useEffect(() => {
    // ユーザー一覧設定が許可されていない場合はスルー
    if (!allowUserListSettings) return;

    // 組織情報がないときはスルー
    if (!record?.oid) return;

    (async () => {
      const { data } = await dataProvider.getAll('users/all', {
        oid: record.oid,
        fields: ['id', 'email'],
      });
      setUsers(data);
    })();
  }, [dataProvider, record?.oid, allowUserListSettings]);

  const onChangeUseOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    if (!checked) {
      if (window.confirm('判例連携オプションの入力内容が破棄されます。よろしいですか？')) {
        setValue(
          'labsWandhD1Option',
          {
            validFrom: null,
            validUntil: null,
            type: null,
            users: [],
          },
          { shouldDirty: true },
        );
        setOption(checked);
      }
      return;
    }

    setOption(checked);
  };

  const optionTypes = {
    allUsers: 'allUsers',
    organization: 'organization',
    specifiedUsers: 'specifiedUsers',
  } as const;

  const filteredOptionTypes = (organizationTypeId: string | null) => {
    const isLegalDepartment = organizationTypeId === '1';
    const isLawFirm = organizationTypeId === '2' || organizationTypeId === '3';
    const isExceptionalOrganization = organizationTypeId === '999';

    if (organizationTypeId === null || isExceptionalOrganization) {
      return labsWandhD1OptionTypes;
    }

    return labsWandhD1OptionTypes.filter((type) => {
      // 企業の場合は「組織適用」を除外
      if (isLegalDepartment) {
        return type.id !== optionTypes.organization;
      }

      // 法律事務所の場合は「全ユーザー適用」を除外
      if (isLawFirm) {
        return type.id !== optionTypes.allUsers;
      }

      return true;
    });
  };

  const onChangeOptionType = (e: RaRecord | React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value;

    if (type === optionTypes.allUsers) {
      setValue('labsWandhD1Option.users', [], { shouldDirty: true });
    }
  };

  const labsWandhD1OptionUsersLabel = (type: typeof optionTypes[keyof typeof optionTypes] | undefined) => {
    switch (type) {
      case optionTypes.organization:
        return '有償/無償判例連携利用可能スタッフ一覧';
      case optionTypes.specifiedUsers:
        return 'ユーザー一覧';
      default:
        return '';
    }
  };

  const showUsers = (type: typeof optionTypes[keyof typeof optionTypes] | undefined) => {
    return type === optionTypes.organization || type === optionTypes.specifiedUsers;
  };

  // バリデーション
  const isInvalidDate = (date: unknown) => {
    if (date === null) {
      return false;
    }
    return date instanceof Date && isNaN(date.getTime());
  };

  const hasChangeDeadlinePassed = (timestamp: number | null) => {
    if (timestamp === null) {
      return false;
    }

    const now = dayjs().tz('Asia/Tokyo').valueOf();
    const startOfNextDay = dayjs(timestamp).tz('Asia/Tokyo').add(1, 'day').startOf('day').valueOf();

    return now >= startOfNextDay;
  };

  const isExpired = (timestamp: number | null) => {
    if (timestamp === null) {
      return false;
    }

    const now = dayjs().tz('Asia/Tokyo').valueOf();

    return now > timestamp;
  };

  type ValidationValues = {
    labsWandhD1Option?: {
      validFrom: Date | string | null;
      validUntil: Date | string | null;
      type: string | null;
      users: Array<{
        email: string | null;
        validFrom: Date | string | null;
        validUntil: Date | string | null;
        type: string | null;
      }>;
    };
  };

  type ReactAdminValidateFunc<T> = (value: T, values: ValidationValues) => string | undefined;

  type ValidateFunc<T> = (args: {
    old:
      | {
          validFrom: Date | string | null | undefined;
          validUntil: Date | string | null | undefined;
          type: string | null | undefined;
        }
      | undefined;
  }) => ReactAdminValidateFunc<T>;

  // 利用開始日時
  const validateValidFrom: ValidateFunc<Date | string | null> =
    ({ old }) =>
    (value) => {
      const oldValidFrom = old?.validFrom ? dayjs(old.validFrom).tz('Asia/Tokyo').valueOf() : null;
      const oldValidUntil = old?.validUntil ? dayjs(old.validUntil).tz('Asia/Tokyo').valueOf() : null;
      const newValidFrom = value ? dayjs(value).tz('Asia/Tokyo').valueOf() : null;

      // DatetimeInputのカレンダーUIのClearボタンを使った場合はInvalid Dateになる
      if (!newValidFrom || isInvalidDate(dayjs(newValidFrom).tz('Asia/Tokyo').toDate())) {
        return 'ra.validation.required';
      }

      // オプション開始日は、その日時が到来していない場合のみ変更可能
      // すでにその日時が到来している場合も、当該日の翌日になるまでであれば変更可能
      // @see https://www.notion.so/legalscape/W-H-17b9c1e22e4980f7a51dc7eb1b0a2336?pvs=4#17b9c1e22e49800480b9c68f5777aa82
      if (hasChangeDeadlinePassed(oldValidFrom) && newValidFrom !== oldValidFrom && !isExpired(oldValidUntil)) {
        return 'error.labsWandhD1OptionValidFromChangeAfterPassedNotAllowed';
      }

      // 過去の日付への変更は不可 (日付をまたがなければ可能)
      // 値が変更されていない場合はスルー
      const startOfToday = dayjs().tz('Asia/Tokyo').startOf('day').valueOf();

      if (newValidFrom !== null && newValidFrom !== oldValidFrom && newValidFrom < startOfToday) {
        return 'error.labsWandhD1OptionPastValidFromNotAllowed';
      }
    };

  // 利用終了日時
  const validateValidUntil: ValidateFunc<Date | string | null> =
    ({ old }) =>
    (value, values) => {
      const oldValidUntil = old?.validUntil ? dayjs(old.validUntil).tz('Asia/Tokyo').valueOf() : null;
      const newValidFrom = values.labsWandhD1Option?.validFrom
        ? dayjs(values.labsWandhD1Option?.validFrom).tz('Asia/Tokyo').valueOf()
        : null;
      const newValidUntil = value ? dayjs(value).tz('Asia/Tokyo').valueOf() : null;

      if (
        !isValidDuration(
          newValidFrom ? dayjs(newValidFrom).tz('Asia/Tokyo').toDate() : null,
          newValidUntil ? dayjs(newValidUntil).tz('Asia/Tokyo').toDate() : null,
        )
      ) {
        return 'error.validUntilShouldBeAfterValidFrom';
      }

      // オプション終了日は、その日時が到来していない場合のみ変更可能
      // すでにその日時が到来している場合も、当該日の翌日になるまでであれば変更可能
      // @see https://www.notion.so/legalscape/W-H-17b9c1e22e4980f7a51dc7eb1b0a2336?pvs=4#17b9c1e22e49800480b9c68f5777aa82
      if (hasChangeDeadlinePassed(oldValidUntil) && newValidUntil !== oldValidUntil) {
        // 終了日が到来していても、既存の終了日以降の開始日と一緒にリクエストされていないと変更不可
        if (newValidFrom && oldValidUntil && newValidFrom < oldValidUntil) {
          return 'error.labsWandhD1OptionValidUntilChangeAfterPassedNotAllowed';
        }
      }

      // 過去の日付への変更は不可 (日付をまたがなければ可能)
      // 値が変更されていない場合はスルー
      const startOfToday = dayjs().tz('Asia/Tokyo').startOf('day').valueOf();

      if (newValidUntil !== null && newValidUntil !== oldValidUntil && newValidUntil < startOfToday) {
        return 'error.labsWandhD1OptionPastValidUntilNotAllowed';
      }
    };

  // 適用方法
  const validateType: ValidateFunc<string | null> =
    ({ old }) =>
    (newType) => {
      const oldValidFrom = old?.validFrom ? dayjs(old.validFrom).tz('Asia/Tokyo').valueOf() : null;
      const oldValidUntil = old?.validUntil ? dayjs(old.validUntil).tz('Asia/Tokyo').valueOf() : null;
      const oldType = old?.type;

      if (newType === null) {
        return 'ra.validation.required';
      }

      // 適用方法は、オプションの有効期間外の場合のみ変更可能
      // すでにオプション開始日が到来している場合も、その翌日になるまでであれば変更可能
      if (hasChangeDeadlinePassed(oldValidFrom) && newType !== oldType && !isExpired(oldValidUntil)) {
        return 'error.labsWandhD1OptionTypeChangeNotAllowed';
      }
    };

  type ValidateUserOptionFunc<T> = (userIndex: number) => ReactAdminValidateFunc<T>;

  // ユーザーのメールアドレス
  const validateUserEmail: ValidateUserOptionFunc<string | null> = (index) => (value, values) => {
    const validations = [required(), email()];

    for (const validate of validations) {
      const error = validate(value, values);
      if (typeof error === 'string' && error) {
        return error;
      }
      if (typeof error !== 'string' && error && error.message) {
        return error.message;
      }
    }

    // 重複チェック
    const users = values.labsWandhD1Option?.users;

    // ユーザーが1人もいない場合はチェックしない
    if (users === undefined || users.length === 0) {
      return;
    }

    const isDuplicate = users.some((user, i) => user.email === value && i !== index);

    if (isDuplicate) {
      return 'error.labsWandhD1OptionUserEmailDuplicate';
    }
  };

  // ユーザーの利用開始日時
  const validateUserValidFrom: ValidateUserOptionFunc<Date | string | null> = (index) => (value, values) => {
    const newOption = values.labsWandhD1Option;
    const newOptionValidFrom = newOption?.validFrom ? dayjs(newOption?.validFrom).tz('Asia/Tokyo').valueOf() : null;
    const newOtionValidUntil = newOption?.validUntil ? dayjs(newOption?.validUntil).tz('Asia/Tokyo').valueOf() : null;
    const newUserValidFrom = value ? dayjs(value).tz('Asia/Tokyo').valueOf() : null;

    // 判例連携オプションのユーザーの利用開始日が、組織の判例連携オプションの有効期間外の場合はエラー
    if (newUserValidFrom !== null && newOptionValidFrom !== null) {
      if (
        newUserValidFrom < newOptionValidFrom ||
        (newOtionValidUntil !== null && newUserValidFrom >= newOtionValidUntil)
      ) {
        return 'error.labsWandhD1OptionUserValidFromOutOfRange';
      }
    }

    const newUser = values.labsWandhD1Option?.users[index];
    const oldUser = record?.labsWandhD1Option?.users.find((user: { email: string }) => user.email === newUser?.email);
    const ignoreError = 'ra.validation.required';
    const validation = validateValidFrom({ old: oldUser })(value, values);

    return validation === ignoreError ? undefined : validation;
  };

  // ユーザーの利用終了日時
  const validateUserValidUntil: ValidateUserOptionFunc<Date | string | null> = (index) => (value, values) => {
    const newOption = values.labsWandhD1Option;
    const newOptionValidFrom = newOption?.validFrom ? dayjs(newOption?.validFrom).tz('Asia/Tokyo').valueOf() : null;
    const newOtionValidUntil = newOption?.validUntil ? dayjs(newOption?.validUntil).tz('Asia/Tokyo').valueOf() : null;
    const newUserValidUntil = value ? dayjs(value).tz('Asia/Tokyo').valueOf() : null;

    // 判例連携オプションのユーザーの利用終了日が、組織の判例連携オプションの有効期間外の場合はエラー
    if (newOptionValidFrom !== null && newOtionValidUntil !== null && newUserValidUntil !== null) {
      if (newUserValidUntil <= newOptionValidFrom || newUserValidUntil > newOtionValidUntil) {
        return 'error.labsWandhD1OptionUserValidUntilOutOfRange';
      }
    }

    const newUser = values.labsWandhD1Option?.users[index];
    const oldUser = record?.labsWandhD1Option?.users.find((user: { email: string }) => user.email === newUser?.email);

    return validateValidUntil({ old: oldUser })(value, values);
  };

  // ユーザー種別
  const validateUserType: ValidateUserOptionFunc<string | null> = (index) => (value, values) => {
    const newUser = values.labsWandhD1Option?.users[index];
    const oldUser = record?.labsWandhD1Option?.users.find((user: { email: string }) => user.email === newUser?.email);
    const error = validateType({ old: oldUser })(value, values);

    if (!error) {
      return;
    }

    if (error === 'ra.validation.required') {
      return error;
    }

    return 'error.labsWandhD1OptionUserTypeChangeNotAllowed';
  };

  // ユーザー削除ボタン
  const canDeleteUser = (index: number) => {
    const newUser = getValues('labsWandhD1Option.users')[index];
    const oldUser = record?.labsWandhD1Option?.users.find((user: { email: string }) => user.email === newUser?.email);
    const oldValidFrom = oldUser?.validFrom ? dayjs(oldUser.validFrom).tz('Asia/Tokyo').valueOf() : null;
    const oldValidUntil = oldUser?.validUntil ? dayjs(oldUser.validUntil).tz('Asia/Tokyo').valueOf() : null;

    // ユーザーのオプション削除は、その日時が到来していない場合のみ変更可能
    // すでにその日時が到来している場合も、当該日の翌日になるまでであれば変更可能
    // @see https://www.notion.so/legalscape/W-H-17b9c1e22e4980f7a51dc7eb1b0a2336?pvs=4#17b9c1e22e4980eab5d5ee827ca1684d
    if (hasChangeDeadlinePassed(oldValidFrom) && !isExpired(oldValidUntil)) {
      return false;
    }

    return true;
  };

  // 全ユーザー削除ボタン
  const canDeleteAllUsers = () => {
    const newUsers = getValues('labsWandhD1Option.users');

    if (newUsers === undefined || newUsers.length === 0) {
      return false;
    }

    if (newUsers.some((_user: object, index: number) => !canDeleteUser(index))) {
      return false;
    }

    return true;
  };

  return (
    <Box pt={0.5}>
      <Typography variant="caption" color="textSecondary">
        {translate('resources.organizations.fields.labsWandhD1Option')}
      </Typography>

      <FormDataConsumer>
        {({ formData }) => (
          <>
            <FormControlLabel
              control={
                <Checkbox id="useLabsWandhD1Option" size="small" checked={useOption} onChange={onChangeUseOption} />
              }
              label="利用する"
              sx={{ display: 'block', width: 'fit-content', '.MuiFormControlLabel-label': { fontSize: '14px' } }}
            />

            {useOption && (
              <>
                <Box display="flex" gap="1em" alignItems="center">
                  <CustomDateTimeInput
                    source="labsWandhD1Option.validFrom"
                    validate={validateValidFrom({ old: record?.labsWandhD1Option })}
                  />
                  <CustomDateTimeInput
                    source="labsWandhD1Option.validUntil"
                    validate={validateValidUntil({ old: record?.labsWandhD1Option })}
                  />
                </Box>
                <SelectInput
                  source="labsWandhD1Option.type"
                  choices={filteredOptionTypes(formData.organizationTypeId)}
                  validate={validateType({ old: record?.labsWandhD1Option })}
                  sx={{ minWidth: 208 }}
                  onChange={onChangeOptionType}
                />
                {allowUserListSettings && showUsers(formData.labsWandhD1Option?.type) && (
                  <ArrayInput
                    source="labsWandhD1Option.users"
                    label={
                      <>
                        {labsWandhD1OptionUsersLabel(formData.labsWandhD1Option?.type)}
                        <Box mt={1}>
                          利用開始日/利用終了日が未入力の場合は、「判例連携オプション」の利用開始日/利用終了日が適用されます。
                          <br />
                          ただし、「判例連携オプション」の利用開始日が過去の場合、「保存」を実行した時点の日時が適用されます。
                        </Box>
                      </>
                    }
                  >
                    <SimpleFormIterator
                      disableClear={!canDeleteAllUsers()}
                      removeButton={
                        /* NOTE: disableRemove={false}を指定するとdisableClearが効かないためremoveButtonでフラグメントを指定 */
                        <></>
                      }
                      disableReordering
                      inline
                      sx={{
                        '.RaSimpleFormIterator-line:hover': {
                          '.remove-item-btn': {
                            display: 'block',
                          },
                        },
                      }}
                    >
                      <FunctionField
                        render={(_record: RaRecord, source: string | undefined) => {
                          const index = Number(source?.split('.').pop() ?? 0);
                          return (
                            <Stack direction="row">
                              <Stack direction="row" sx={{ columnGap: '1em', flexWrap: 'wrap' }}>
                                <AutocompleteInput
                                  source={`${source}.email`}
                                  choices={users}
                                  optionValue="email"
                                  optionText="email"
                                  validate={validateUserEmail(index)}
                                  sx={{ width: 300 }}
                                />
                                <DatetimeInput source={`${source}.validFrom`} validate={validateUserValidFrom(index)} />
                                <DatetimeInput
                                  source={`${source}.validUntil`}
                                  validate={validateUserValidUntil(index)}
                                />
                                <SelectInput
                                  source={`${source}.type`}
                                  choices={labsWandhD1OptionUserTypes}
                                  defaultValue="paid"
                                  validate={validateUserType(index)}
                                />
                              </Stack>

                              <Box mt={0.5}>
                                <RemoveItemButton
                                  className="remove-item-btn"
                                  disabled={!canDeleteUser(index)}
                                  sx={{ display: 'none' }}
                                />
                              </Box>
                            </Stack>
                          );
                        }}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                )}
              </>
            )}
          </>
        )}
      </FormDataConsumer>
    </Box>
  );
};
