import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Box } from '@mui/material';
import { useRecordContext } from 'react-admin';
import TranslatedField from './TranslatedField';

export const RequestType: React.FC = () => {
  const record = useRecordContext();

  if (record && record.requestType) {
    return (
      <Box display="flex" alignItems="center">
        {record.requestType === 'addUserRequest' && <PersonAddIcon color="primary" />}
        {record.requestType === 'editUserRequest' && <EditIcon color="secondary" />}
        {record.requestType === 'deleteUserRequest' && <PersonRemoveIcon color="disabled" />}
        {record.requestType === 'enrollmentRequest' && <CorporateFareIcon color="primary" />}
        {record.requestType === 'replaceUserRequest' && <SwapHorizIcon color="primary" />}
        <TranslatedField source="requestType" sortable={false} prefix="enum.requestType" sx={{ marginLeft: 1 }} />
      </Box>
    );
  }

  return null;
};
