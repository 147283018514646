import { DurationState, getDurationState } from 'src/utils/duration';
import { Organization } from './types';

// https://www.notion.so/legalscape/0436253826e443ccb41463b4d249de17

export type OrganizationState = `${DurationState}Trial${Capitalize<DurationState>}Paid`;

function capitalize<A extends string>(str: A): Capitalize<A> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<A>;
}

function newDateOrNull(str: string | null): Date | null {
  if (str === null) {
    return null;
  }
  return new Date(str);
}

export function getOrganizationState(organization: Organization, now: Date): OrganizationState {
  const trialState = getDurationState(
    now,
    newDateOrNull(organization.trialFrom),
    newDateOrNull(organization.trialUntil),
  );
  const paidState = getDurationState(now, newDateOrNull(organization.paidFrom), newDateOrNull(organization.paidUntil));

  return `${trialState}Trial${capitalize(paidState)}Paid` as const;
}

export function canCreateTrialRequest(organization: Organization, now: Date): boolean {
  const eligibleStates: OrganizationState[] = ['beforeTrialNoPaid', 'withinTrialNoPaid', 'withinTrialBeforePaid'];

  return eligibleStates.includes(getOrganizationState(organization, now));
}

export function canCreateEnrollmentRequest(organization: Organization, now: Date): boolean {
  const eligibleStates: OrganizationState[] = [
    'noTrialBeforePaid',
    'withinTrialNoPaid',
    'afterTrialNoPaid',
    'withinTrialBeforePaid',
    'afterTrialBeforePaid',
  ];

  return eligibleStates.includes(getOrganizationState(organization, now));
}

export function canCreateAddAccountRequest(organization: Organization, now: Date): boolean {
  const eligibleStates: OrganizationState[] = ['noTrialWithinPaid', 'afterTrialWithinPaid'];

  return eligibleStates.includes(getOrganizationState(organization, now));
}

export function canCreateEditAccountRequest(organization: Organization, now: Date): boolean {
  return canCreateEnrollmentRequest(organization, now) || canCreateAddAccountRequest(organization, now);
}

export function canCreateDeleteAccountRequest(organization: Organization, now: Date): boolean {
  return canCreateEnrollmentRequest(organization, now) || canCreateAddAccountRequest(organization, now);
}

export function canCreateReplaceAccountRequest(organization: Organization, now: Date): boolean {
  return canCreateAddAccountRequest(organization, now) && canCreateDeleteAccountRequest(organization, now);
}

// 本利用開始のメールを送れるのは本利用開始日が決定後であるため本利用のみ申請と承認を分けて考える
export function canTreatEnrollmentRequest(organization: Organization, now: Date): boolean {
  const eligibleStates: OrganizationState[] = [
    'noTrialBeforePaid',
    'withinTrialBeforePaid',
    'afterTrialBeforePaid',
    // 本利用開始後にまだ承認できていなかった申請が残っている可能性があるためwithin paidでも承認できるようにする
    'noTrialWithinPaid',
    'afterTrialWithinPaid',
  ];

  return eligibleStates.includes(getOrganizationState(organization, now));
}
